import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import '../style/thank-you.scss';

import SEO from '../components/seo';
import Layout from '../layouts/plain';
import Title from '../components/title';
import useLanguageStrings from '../hooks/useLanguageStrings';

const query = graphql`
  {
    pageEN: contentfulPage(contentful_id: { eq: "YmaBvGnLX8tdxuqAhcQye" }) {
      title
      lead
      body {
        json
      }
    }
    pageDA: contentfulPage(
      contentful_id: { eq: "YmaBvGnLX8tdxuqAhcQye" }
      node_locale: { eq: "da" }
    ) {
      title
      lead
      body {
        json
      }
    }
  }
`;

const outboundLink = 'https://weareheadlight.com/';

const ThankYou = () => {
  const { pathname } = useLocation();

  const { bookFreeSession } = useLanguageStrings();
  const { pageEN, pageDA } = useStaticQuery(query);
  const { title, lead, body } = pathname.indexOf('/en') === 0 ? pageEN : pageDA;
  const BodyComponent = useMemo(() => documentToReactComponents(body.json), []);
  return (
    <>
      <SEO title={title} description={lead} />
      <Layout id="thank-you" theme="theme-yellow">
        <header>
          <Title />
        </header>
        <main>
          <h1>{title}</h1>
          {BodyComponent}
          <a href={outboundLink} className="button">
            {bookFreeSession}
          </a>
        </main>
      </Layout>
    </>
  );
};

export default ThankYou;
